const cpfFormatter = (originalCPF: bigint) => {
  let string = originalCPF.toString().padStart(11, "0");
  const cpf = string.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

const phoneNumberFormatter = (phoneNumber: string) => {
  // Remove all non-numeric characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");

  // Check if the phone number has 11 digits (including country code)
  if (cleanedPhoneNumber.length === 11) {
    // Format as (XX) XXXXX-XXXX (with country code)
    return cleanedPhoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (cleanedPhoneNumber.length === 10) {
    // Format as (XX) XXXXX-XXXX (without country code)
    return cleanedPhoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else {
    // Return the original phone number if it doesn't match expected formats
    return phoneNumber;
  }
};

const isAdminEmail = (email: string) => {
  const emailDomain = email.match(/@([^@]+)$/) || "";
  if (
    ["@enjoyconceito.com.br"].includes(emailDomain[0]) ||
    ["@recriv.com.br"].includes(emailDomain[0])
  ) {
    return true;
  } else {
    return false;
  }
};

const phoneMask = (value) => {
  if (!value) return "";

  if (value.length > 15) {
    value = value.substring(0, 15);
  }
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");

  return value;
};

const onlyNumbers = (value) => {
  const num = value.replace(/\D/g, "");
  return num;
};

export default {
  cpfFormatter,
  phoneNumberFormatter,
  isAdminEmail,
  phoneMask,
  onlyNumbers,
};
