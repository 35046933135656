import React, { useEffect, useState, useMemo } from "react";
import "./productList.css";
import { IProduct } from "../../interfaces/IProduct";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";

const formatador = Intl.NumberFormat("pt-br", {
  style: "currency",
  currency: "BRL",
});

const ProductList = ({ products, columnFilters, setColumnFilters }) => {
  const columns = [
    {
      accessorKey: "titulo",
      header: "Produto",
      cell: (props) => (
        <p style={{ fontWeight: "500" }}>{props.getValue()?.toUpperCase()}</p>
      ),
    },
    {
      accessorKey: "preco",
      header: "Valor",
      cell: (props) => (
        <p style={{ fontWeight: "500" }}>
          {formatador.format(props.getValue())}
        </p>
      ),
    },
    {
      accessorKey: "statusvenda",
      header: "Status",
      cell: (props) => (
        <div>
          {props.getValue() === 0 && (
            <p style={{ color: "green", fontWeight: "600" }}>Disponível</p>
          )}
          {props.getValue() === 1 && (
            <p style={{ color: "red", fontWeight: "600" }}>Vendido</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "statuspagamento",
      header: "Pagamento",
      cell: (props) => (
        <p>
          {props.getValue() === 2 && "Cashback"}
          {props.getValue() === 1 && "Pago"}
          {props.getValue() === 0 && "Pendente"}
        </p>
      ),
    },
  ];

  const [data, setData] = useState(products);

  const table = useReactTable({
    data: products,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columnResizeMode: "onChange",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filterData = () => {
    const filtered = products.filter((product) => {
      const itemDate = new Date(product.date);
      const start = new Date(startDate) || null;
      const end = new Date(endDate) || null;

      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true;
    });
    setData(filtered);
  };

  const resetFilter = () => {
    setStartDate("");
    setEndDate("");
    setData(products);
  };

  useEffect(() => {}, []);

  return (
    <table className="table-data" width={table.getTotalSize()}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th width={header.getSize()} key={header.id}>
                {header.column.columnDef.header}
                <div
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`resizer ${
                    header.column.getIsResizing() ? "isResizing" : ""
                  }`}
                ></div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              width={cell.column.getSize()}
              key={cell.id}
              data-label={cell.column.columnDef.header}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export default ProductList;
